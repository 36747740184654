.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 화면 크기에 맞춘 전체 화면 컨테이너 */
.App-full-screen-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90vh;
}

/* 전체 화면 컨테이너 */
.App-full-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* 전체 화면 컨테이너 */
.App-full-row-container {
    display: flex;
    /* justify-content: center;
    align-items: center; */
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.App-full-row-container-1 {
    display: flex;
    justify-content: space-evenly;
    /* align-items: center; */
    flex-direction: row;
    flex-wrap: wrap;
    /* width: 95%; */
    gap: 10px;
}

/* 세로 방향으로 오브젝트를 정렬하는 컨테이너 */
.vertical-flex-container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

/* 세로 방향으로 오브젝트를 정렬하는 컨테이너 */
.vertical-flex-container-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 25px;
}

.vertical-flex-container-2 {
    display: flex;
    flex-direction: column;
    row-gap: 7px;
}

/* 가로 방향으로 오브젝트를 정렬하는 컨테이너 */
.horizontal-flex-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

/* border가 있는 최대 폭 400px의 중앙 정렬 박스 */
.position-center-box {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.7);
    width: 100%;
    max-width: 400px;
}

/* border가 없는 최대 폭 400px의 중앙 정렬 박스 1 */
.position-center-box-1 {
    background-color: white;
    padding: 20px;
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    width: 100%;
    margin: 7px 5px 1px 5px;
    max-width: 400px;
}

/* border가 없는 최대 폭 400px의 중앙 정렬 박스 2 */
.position-center-box-1-1 {
    background-color: white;
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    width: 100%;
    margin: 7px 5px 1px 5px;
    max-width: 400px;
}

/* 최대 폭 700px의 중앙 정렬 박스 */
.position-center-box-2 {
    background-color: white;
    /* justify-content:center; */
    width: 100%;
    max-width: 700px;
}

/* 최대 폭 900px의 중앙 정렬 박스 */
.position-center-box-2-1 {
    background-color: white;
    /* justify-content:center; */
    width: 100%;
    max-width: 900px;
}

.position-center-box-2-2 {
  background-color: white;
  /* justify-content:center; */
  width: 48%;
  max-width: 440px;
}

/* 왼쪽 메뉴를 보여주는 박스 */
.left-menu-box-1 {
    background-color: white;
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    margin: 7px 3px 1px 0px;
    width: 230px;
    min-width: 228px;
}

/* 왼쪽의 문제 리스트 메뉴를 보여주는 박스 */
.subject-sidebar-1 {
  position: sticky;
  top: 5px;
  min-width: 200px;
  max-width: 230px;
}

.subject-sidebar-2 {
  list-style: none;
  margin-top: 0.7rem;
  margin-left: 5px;
  margin-bottom: 0px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.subject-sidebar-3 {
  list-style: none;
  padding-left: 8px;
  font-size: 0.9rem;
}

.subject-sidebar-4 {
  list-style: none;
  margin-top: 0.3rem;
  margin-left: 0.7rem;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

/* 컨텐트를 제공하는 박스 */
.content-box-1 {
    background-color: white;
    /* padding: 20px; */
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    margin: 7px 5px 1px 0px;
    width: 72%;
    min-width: 390px;
}

.problem {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.problemmain {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    object-fit: contain;
    border: #282c34 solid 1px;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

/* 카드들의 리스트를 보여주는 타이틀 */
.card-list-title {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid rgba(0, 0, 0, 0.411);
    padding: 15px;
    background-color: whitesmoke;
    border-radius: 5px;
    color: black;
}

.card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    gap: 20px;
}

.card {
    width: 300px;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.411);
    background-color: whitesmoke;
    border-radius: 7px;
}

.card-2 {
    width: 230px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.411);
    background-color: whitesmoke;
    border-radius: 7px;
}

/* 문제 폭에 맞추기 */
.content-fit {
  width: min-content;
}

/* 폭 70% 박스 */
.width-70-percent-box {
    width: 70%;
}

/* 폭 100% 박스 */
.width-100-percent-box {
    width: 100%;
}

/* 로그인에서 에러 메세지를 보여주기 위한 CSS */
.offscreen {
    position: absolute;
    left: -9999px;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

/* 폭이 고정된 컨테이너 및 박스의 반응형 미디어 대응 */
@media only screen and (max-width: 768px) {
    .position-center-box-2 {
        max-width: 400px;
    }

    .width-70-percent-box {
        width: 98%;
    }

    .App-full-row-container {
        flex-direction: column-reverse;
        gap: 25px;
    }

    .content-box-1 {
        width: 98%;
        min-width: 390px;
    }

    .position-center-box-2-2 {
        width: 98%;
    }

    .left-menu-box-1 {
        width: 98%;
        min-width: 228px;
    }

    .card {
        width: 98%;
    }
    
    .card-2 {
        width: 98%;
    }

    /* .subject-sidebar-1 {
        width:98%;
    } */

    .App-full-screen-container {
        width: 98vw;
    }
    
    /* 전체 화면 컨테이너 */
    .App-full-container {
        width: 98vw;
    }
    
    /* 전체 화면 컨테이너 */
    .App-full-row-container {
        width: 98vw;
    }
    
    .App-full-row-container-1 {
        width: 98vw;
    }

    .problem {
        width: 99%;
    }
}


/* .App-container {
  max-width: 98vw;
  display: grid;
  grid-template-columns: repeat(5, minmax(10px, 1fr));
  grid-auto-rows: minmax(40px, auto);
  margin-left: 1vw;
  margin-right: 1vw;
}

.App-container .boxApp-1 {
  grid-column: 1 / 6;
  grid-row: 1;
}

.App-container .boxApp-2 {
  grid-column: 1 / 6;
  grid-row: 2;
}

.App-container .boxApp-3 {
  grid-column: 1 / 6;
  grid-row: 3;
  color: black;
  background-color: #f0f0f0;
  font-size: 1.2rem;
  text-align: center;
  text-justify: distribute;
  padding: 0.5rem;
} */

.imagefit {
  max-width: 100%;
  object-fit: contain;
} 

a {
  outline: none;
  text-decoration: none;
  /* padding: 2px 1px 0; */
}

a:link {
  color: blue;
  text-decoration: none;
  font-weight: bolder;
}

a:visited {
  color: blueviolet;
}

.button {
  background-color: green;
  border: 0px green;
  border-radius: 5px;
  color: white;
  padding: 7px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  /* margin-right: 20px; */
  margin-top: 15px;
  margin-bottom: 10px;
}

.button:hover {
  background-color: darkgreen;
}


/* 
.problemContentFit {
  width: min-content;
}

.problemmove {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  object-fit: contain;
}

.problemDescription {
  flex-basis: min-content;
} */

